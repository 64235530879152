<template>
  <form @submit.prevent="saveData">
    <div class="row mt-4 align-items-center">
      <!-- asignar a vehiculo -->
      <div class="col-xl-6 mb-3">
        <div>
          <label for="placaVehiculo" class="form-label">
            <em class="text-danger font-weight-bolder">Paso 1</em> -
            Vehículo</label
          >
          <!-- vue-select -->
          <div style="background: transparent; padding: 0rem">
            <v-select
              v-model="newService.PlacaId"
              class="style-chooser"
              :reduce="(value) => value.id"
              :options="listaVehiculos"
              :get-option-label="(option) => `${option.placa}`"
              placeholder="-- seleccionar vehículo --"
            >
              <!-- required -->
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!newService.PlacaId"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <!-- sin resultados en la busquedad -->
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontraron resultados para
                  <em class="text-danger">{{ search }}</em
                  >.
                </template>
                <em v-else style="opacity: 0.5"
                  >No hay vehículos para mostrar.</em
                >
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <!-- tipo servicio -->
      <div class="col-xl-6 mb-3">
        <div>
          <label for="tipoServicio" class="form-label">
            <em class="text-danger font-weight-bolder">Paso 2</em> - Tipo
            Servicio</label
          >
          <!-- vue-select -->
          <div style="background: transparent; padding: 0rem">
            <v-select
              v-model="newService.TipoServicioId"
              class="style-chooser"
              :reduce="(value) => value.id"
              :options="listaTipoServicios"
              :get-option-label="(option) => `${option.descripcion}`"
              placeholder="-- seleccionar tipo servicio --"
            >
              <!-- required -->
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!newService.TipoServicioId"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <!-- sin resultados en la busquedad -->
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontraron resultados para
                  <em class="text-danger">{{ search }}</em
                  >.
                </template>
                <em v-else style="opacity: 0.5"
                  >No hay vehículos para mostrar.</em
                >
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <!-- descripcion -->
      <div class="col-12 mb-3">
        <label for="descripcion" class="form-label"
          ><em class="text-danger font-weight-bolder">Paso 3</em> -
          Descripción</label
        >
        <b-form-textarea
          v-model="newService.Descripcion"
          placeholder="Escriba una descripción..."
          rows="7"
          minlength="10"
          maxlength="550"
        ></b-form-textarea>
      </div>
    </div>
    <!-- btn agregar servicio -->
    <div class="d-flex justify-content-end">
      <vs-button :loading="isCreating" type="submit" animation-type="vertical">
        Agregar Servicio
        <template #animate> <i class="fas fa-plus-circle"></i></template>
      </vs-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    sharedService: Function,
  },
  data() {
    return {
      persona: {},
      isCreating: false,
      listaVehiculos: [],
      listaTipoServicios: [],
      newService: {
        PersonaId: null,
        PlacaId: null,
        TipoServicioId: null,
        Descripcion: null,
        FechaInicio: null,
        FechaFin: null,
      },
    };
  },
  async mounted() {
    // this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getVehiculosByEmpresa();
    await this.getListTipoServicios();
    // this.$isLoading(false);
  },
  methods: {
    initForm() {
      for (let prop in this.newService) {
        this.newService[prop] = null;
      }
    },
    getPlacaVehiculo(vehiculoId) {
      return this.listaVehiculos.find((v) => v.id === vehiculoId).placa;
    },
    getTipoServicio(servicioId) {
      return this.listaTipoServicios.find((v) => v.id === servicioId);
    },
    async getListTipoServicios() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "TipoServicios/ListTipoServicios",
        });
        if (res.length > 0) {
          this.listaTipoServicios = res;
          ////console.log(res)
        } else {
          this.listaTipoServicios = [];
        }
      } catch (error) {
        this.listaTipoServicios = [];
        console.log("err", error);
      }
    },
    async getVehiculosByEmpresa() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/VehiculosPorEmpresa/" + this.persona.empresa.id,
        });
        console.log("list vehiculos...", res);
        if (res.length > 0) {
          this.listaVehiculos = res;
        } else {
          this.listaVehiculos = [];
        }
      } catch (error) {
        this.listaVehiculos = [];
        console.log("err", error);
      }
    },
    async saveData() {
      try {
        this.isCreating = true;
        const newService = {
          ...this.newService,
          PersonaId: this.persona.id,
          FechaInicio: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm"),
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "RegistroServicios/",
          data: newService,
        });
        if (res.success) {
          this.initForm();
          const {
            PersonaId,
            PlacaId,
            TipoServicioId,
            Descripcion,
            FechaInicio,
            FechaFin,
          } = newService;
          let sharedService = {
            id: res.data,
            personaId: PersonaId,
            placaId: PlacaId,
            placa: this.getPlacaVehiculo(PlacaId),
            tipoServicioId: TipoServicioId,
            tipoServicio: this.getTipoServicio(TipoServicioId),
            descripcion: Descripcion,
            fechaInicio: FechaInicio,
            fechaFin: FechaFin,
            estado: FechaFin ? true : false,
          };
          this.sharedService(sharedService);
          this.isCreating = false;
          this.$vs.notification({
            //duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "success",
            position: "top-right",
            title: "Servicio Creado!",
          });
        }
      } catch (error) {
        console.log(error);
        return null; // Handle the error as needed
      }
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
